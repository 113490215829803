
import InputSwitch from "primevue/inputswitch"
import LaptopBatterHealth from "@/components/computers/tools/LaptopBatteryHealth.vue"
import { ref, reactive } from "vue"

export default {
  components: {
    LaptopBatterHealth,
    InputSwitch,
  },
  name: "ComputerSpecs",
  props: {
    computer: {
      type: Object,
      default: null,
    },
  },
  setup(props: any) {
    const pcPhysicalMemoryTotal = ref(convertBytesToGB(props.computer.pcPhysicalMemoryTotal))
    const cDiskFreeSpace = ref(convertBytesToGB(props.computer.cdiskFreeSpace))
    const rDiskFreeSpace = ref(convertBytesToGB(props.computer.rdiskFreeSpace))
    const firewallDomain = ref(props.computer.osFirewallDomain)
    const firewallPrivate = ref(props.computer.osFirewallPrivate)
    const firewallPublic = ref(props.computer.osFirewallPublic)

    function convertBytesToGB(bytes : number) {
      if(bytes === null) return null;
      return (bytes / (1024 * 1024 * 1024)).toFixed(2)
    }

    return {
      pcPhysicalMemoryTotal,
      cDiskFreeSpace,
      rDiskFreeSpace,
      firewallDomain,
      firewallPrivate,
      firewallPublic,
    }
  },
}
