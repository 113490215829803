
import { ref } from "vue"

export default {
  name: "LaptopBatterHealth",
  props: {
    batteryHealth: {
      type: Number,
      default: null,
    },
    fullCapacity: {
      type: Number,
      default: null,
    },
  },
  setup(props: any) {
    const healthConfig = [
      {
        id: "low",
        threshold: 50,
      },
      {
        id: "medium",
        threshold: 70,
      },
      {
        id: "high",
        threshold: 90,
      },
      {
        id: "veryhigh",
        threshold: 101,
      },
    ]

    //const percentage = (props.batteryHealth / props.fullCapacity) * 100
    let failSafePercentage = 0
    if (props.batteryHealth && props.batteryHealth !== "NaN") {
      failSafePercentage = props.batteryHealth > 100 ? 100 : props.batteryHealth
    }
    const formattedPercentage = failSafePercentage.toFixed(2)

    const currentBattery = ref()

    const calculateCurrentBattery = function (value: any) {
      currentBattery.value = healthConfig.find((battery) => value < battery.threshold)
      if (!currentBattery.value) {
        currentBattery.value = "undefined"
      }
    }
    calculateCurrentBattery(formattedPercentage)

    return {
      currentBattery,
      formattedPercentage,
      calculateCurrentBattery,
    }
  },
}
