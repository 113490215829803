import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-dd77a632"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "battery-health" }
const _hoisted_2 = { class: "" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mdicon = _resolveComponent("mdicon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.currentBattery)
      ? (_openBlock(), _createBlock(_component_mdicon, {
          key: 0,
          class: _normalizeClass($setup.currentBattery.id + 'health'),
          name: "battery"
        }, null, 8, ["class"]))
      : _createCommentVNode("", true),
    _createElementVNode("label", _hoisted_2, _toDisplayString($setup.formattedPercentage) + " %", 1)
  ]))
}