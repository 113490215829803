import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4c71084a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "tab-body comp-profile-tab-body"
}
const _hoisted_2 = { class: "flex computer-profile flex-wrap" }
const _hoisted_3 = { class: "computer-form" }
const _hoisted_4 = { class: "computer-specs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ComputerForm = _resolveComponent("ComputerForm")!
  const _component_ComputerSpecs = _resolveComponent("ComputerSpecs")!

  return (_ctx.computer)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_ComputerForm, {
              computer: _ctx.computer,
              onSubmit: _ctx.onSaveChanges,
              ref: "form"
            }, null, 8, ["computer", "onSubmit"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_ComputerSpecs, { computer: _ctx.computer }, null, 8, ["computer"])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}