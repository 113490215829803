
import { defineComponent, ref } from "vue"
import ComputerService from "@/services/ComputerService"
import ComputerSpecs from "@/components/computers/ComputerSpecs.vue"

export default defineComponent({
  components: { ComputerSpecs },
  props: {
    computer: {
      type: Object,
      default: null,
    },
  },
  emits: ["value-changed"],
  setup(props, { emit }) {
    const form = ref()
    const computerService = new ComputerService()

    function onSaveChanges(computerData: any) {
      computerService.updateComputer(props.computer.id, computerData).then(() => {
        // Do nothing
        emit("value-changed")
        form.value.onDone()
      })
    }
    return {
      form,
      onSaveChanges,
    }
  },
})
